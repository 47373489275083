<template>
    <div class="home">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 主体内容 -->
        <div class="voucherContent" v-loading="loading">

            <!-- tabs -->
            <div class="tabsBox">
                <div class="tabs" :style="{ width: tabsList.length * 84 + 'px' }">
                    <div class="son" :class="[i.act ? 'tabsAct' : '']" @click="tabCilck(index)"
                        :style="{ borderLeft: index == 1 || index == 3 ? '1px solid #e0e0e0' : '', borderRight: index == 1 || index == 3 ? '1px solid #e0e0e0' : '' }"
                        v-for="(i, index) in tabsList" :key="index">{{ i.name }}</div>
                </div>
                <div class="my cur" @click="myYhqClick">我的优惠券（{{shoppingListTotal2}}）</div>
            </div>

            <!-- 倒计时 -->
            <!-- <div class="countDown">
                <div class="l">
                    <div class="text">您的 <span>满5000减500元</span> 的优惠券即将过期</div>
                    <div class="djs">
                        <div>{{ formatNumber(hours) }}</div> <span>:</span>
                        <div>{{ formatNumber(minutes) }}</div> <span>:</span>
                        <div>{{ formatNumber(seconds) }}</div>
                    </div>
                </div>
                <div class="r">去看看</div>
            </div> -->

            <!-- 优惠券列表 -->
            <div class="yhqList" >
                <div class="son" v-for="(item, index) in ListOfCoupons" :key="index">
                    <div class="l">
                        <div class="jiag" v-if="item.type == '1' || item.type == '4'"> ￥<span>{{ item.reduceAmount ||
                            item.fixationAmount }}</span> </div>
                        <div class="jiag" v-if="item.type == '3'"> ￥<span>{{ item.fixationAmount }}</span> </div>
                        <div class="jiag" v-if="item.type == '2'"> <span>{{ item.discount }}</span> 折</div>
                        <div class="text">满{{ item.premiseAmount }}可用</div>
                    </div>
                    <div class="r">
                        <div class="name">{{ item.storeName }}</div>
                        <div class="text" v-show="item.suitType == '1'">全部商品可用</div>
                        <div class="text" v-show="item.suitType == '2'">指定商品可用</div>
                        <div class="btn" v-show="!item.couponUser && !item.couponTname" @click="claimCouponsClick(item)">立即领券</div>
                        <!-- <div class="btn btn2">过期失效</div> -->
                        <div class="btn btn3" v-show="item.couponUser || item.couponTname" @click="searchClick(item)">立即使用</div>
                        <div class="prompt">领取后{{ item.validDays }}天内有效</div>
                    </div>
                    <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                    <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                    <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                    <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                </div>
            </div>

            <!-- 分页 -->
            <!-- <div class="paging">
                <el-pagination background layout="prev, pager, next" :total="100">
                </el-pagination>
            </div> -->
            <paging :total="shoppingListTotal"  :pageSize="paging.size" @handleCurrentChange="handleCurrentChange"></paging>
        </div>



        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import { getmyCoupon } from "@/utils/api/myApi/index"
import { getListOfCoupons,postClaimCoupons } from "@/utils/api/homeApi/index"
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import paging from "@/components/paging.vue";
export default {
    name: 'brandCenter',
    components: {
        largeHead,
        bottomContent,
        paging
    },
    data() {
        return {
            loading: true,
            // 头部激活菜单
            tabArrShow: 4,
            tabsList: [
                {
                    name: '全部',
                    act: true
                },
                {
                    name: '减满券',
                    act: false
                },
                {
                    name: '折扣券',
                    act: false
                },
                {
                    name: '固定金额券',
                    act: false
                },
                // {
                //     name: '卡密券',
                //     act: false
                // },
            ],
            // 倒计时
            countDownDate: new Date("2023-05-07 15:18:40").getTime(), // 目标日期
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            timer: null, // 定时器
            // 分页
            shoppingListTotal: 0,
            shoppingListTotal2: 0,
            paging: {
                current: 1,
                size: 30,
                share: 1,
                type:'',
            },
            ListOfCoupons: [],

        };
    },
    created() {
        // 更新倒计时
        this.updateCountdown();
        this.timer = setInterval(this.updateCountdown, 1000); // 每秒更新一次
    },
    mounted() {
        this.getOfCoupons()
        this.getCoupon()
    },
    methods: {
        // 跳转到我的优惠券页面
        myYhqClick(){
            window.open(`#/myIndex/myCoupon?actMyNum=2`, '_blank');
        },
         // 获取我的优惠券
         async getCoupon() {
            const res = await getmyCoupon({
                status: '',
                current: 1,
                size: 10
            })
            if (res.statusCode == 8201) {
                this.shoppingListTotal2 = res.data.total
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.loading = true
            console.log(val)
            this.paging.current = val
            this.getOfCoupons()
        },
        // 获取优惠券列表
        async getOfCoupons() {
            const res = await getListOfCoupons(this.paging)
            if (res.statusCode == 8201) {
                this.ListOfCoupons = res.data.records
                this.shoppingListTotal = res.data.total
                this.loading = false
                console.log(res)
            }
        },
        // 领取优惠券
        async claimCouponsClick(val){
            const res = await postClaimCoupons({
                id:val.id,
                storeId:val.storeId
            })
            console.log(res)
            if(res.statusCode == 8201) {
                this.$message.success('领取成功');
                this.getOfCoupons()
            } else if (res.statusCode == 7001)  {
                this.$message.error(res.message);
            } else {
                this.$message.error('领取失败');
            }
        },
        tabCilck(index) {
            this.tabsList.map((t) => (t.act = false));
            this.tabsList[index].act = true;
            if(index == 0) this.paging.type = ''
            if(index == 1) this.paging.type = '1'
            if(index == 2) this.paging.type = '2'
            if(index == 3) this.paging.type = '3'
            if(index == 4) this.paging.type = '4'
            console.log(this.paging.type)
            this.getOfCoupons()
        },
        // 倒计时
        updateCountdown() {
            var now = new Date().getTime();
            var distance = this.countDownDate - now;
            if (distance <= 0) {
                clearInterval(this.timer); // 停止计时器
                return;
            }
            this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        },
        formatNumber(num) {
            return num < 10 ? "0" + num : num;
        },
        // 根据优惠券搜索商品
        searchClick(i) {
            let spuIds = ''
            if(i.spuIds){
                spuIds = i.spuIds.join(',')
            }
            window.open(`#/search2?storeId=${i.storeId}&type=coupon&spuIds=${spuIds}`, '_blank');
        },
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;


    // 主题内容
    .voucherContent {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        border-radius: 4px;
        background-color: #fff;
        padding: 30px;
        box-sizing: border-box;

        // tabs
        >.tabsBox {
            width: 100%;
            height: 45px;
            padding: 8px 10px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F5F5F5;

            >.tabs {
                display: flex;
                border: 1px solid #E0E0E0;
                background-color: #fff;
                box-sizing: border-box;
                color: #333;


                >.son {
                    width: 84px;
                    height: 30px;
                    box-sizing: border-box;
                    // border: 1px solid #fff;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                    text-align: center;
                    transition: all .3s;
                    cursor: pointer;
                }
            }

            >.my {
                font-size: 14px;
                color: #333;
            }
        }

        // 倒计时
        >.countDown {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #FFF3F3;
            padding: 10px 20px;
            box-sizing: border-box;
            margin-top: 20px;

            >.l {
                width: 470px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-right: 20px;
                margin-left: 10px;

                >.text {
                    font-size: 14px;
                    color: #333;
                    margin-right: 15px;

                    >span {
                        color: #FF4242;
                    }
                }

                >.djs {
                    display: flex;
                    align-items: center;

                    >div {
                        width: 24px;
                        height: 24px;
                        border-radius: 4px;
                        text-align: center;
                        line-height: 24px;
                        font-size: 14px;
                        color: #fff;
                        background-color: #FF3C3C;
                    }

                    >span {
                        color: #FF3C3C;
                        margin: 0 5px;
                    }
                }
            }

            >.r {
                width: 92px;
                height: 30px;
                border-radius: 20px;
                background-color: #FF4242;
                color: #fff;
                font-size: 14px;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
            }
        }

        // 优惠券列表
        >.yhqList {
            width: 100%;
            height: auto;
            min-height: 320px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            >.son {
                width: 360px;
                height: 120px;
                margin-top: 20px;
                background: url(../assets/lqyhbg.png);
                background-size: 100% 100%;
                display: flex;
                position: relative;

                >.l {
                    width: 120px;
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    >.jiag {
                        color: #FF4242;
                        font-size: 16px;

                        >span {
                            font-size: 30px;
                            font-weight: bold;
                        }
                    }

                    >.text {
                        font-size: 12px;
                        color: #666;
                        margin-top: 5px;
                    }
                }

                >.r {
                    width: 240px;
                    height: 120px;
                    padding: 20px 10px;
                    box-sizing: border-box;
                    box-sizing: border-box;

                    >.name {
                        font-size: 14px;
                        color: #333;
                    }

                    >.text {
                        font-size: 12px;
                        color: #999;
                        margin-top: 2px;
                    }

                    >.btn {
                        width: 71px;
                        height: 24px;
                        border-radius: 18px;
                        background-color: #FF4242;
                        color: #fff;
                        cursor: pointer;
                        text-align: center;
                        line-height: 24px;
                        font-size: 14px;
                        margin-left: 140px;
                        margin-top: 5px;
                    }

                    >.btn2 {
                        background-color: #999999;
                    }

                    >.btn3 {
                        border: 1px solid #FF4242;
                        background-color: rgba(255, 66, 66, .2);
                        color: #FF4242;
                    }


                    >.prompt {
                        color: #999;
                        font-size: 12px;
                        margin-left: 120px;
                        margin-top: 3px;
                    }
                }

                >img {
                    width: auto;
                    height: 18px;
                    position: absolute;
                    top: 4px;
                    left: 8px;
                }
            }
        }

        // 分页
        .paging {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 0px;
        }
    }
}

.tabsAct {
    background-color: #FF4242;
    color: #fff;
    transition: all .3s;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>